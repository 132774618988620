import React,{Component} from 'react';
import './App.css';
import { Layout } from './components/Layout/Layout';

class App extends Component {
  render() {
    return (
      <Layout></Layout>
    )
  }
}
export default App;
